.shippingparent{
    display: flex;
    justify-content: center;
    margin-top: 6rem;
}
.shippingchild{
    width: 90%;
    padding: 10px 10px 50px 10px;
}
.shippingaddress{
    background: #fff;
    padding: 15px;
    margin-top: 15px;
    box-shadow:  rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}
.Deladdress{
    font-family: sans-serif;
    color: rgb(45, 44, 44);
}
.customername{
    font-family: sans-serif;
    margin-top: 10px;
    font-weight: bold;
    color: rgb(45, 44, 44);
}
.address{
    font-family: sans-serif;
    margin-top: 10px;
    width: 40%;
    line-height: 25px;
    color: rgb(45, 44, 44);
}
.phndetails{
    display: flex;
    margin-top: 10px;
}
.phonenbr{
    font-family: sans-serif;
    font-weight: bold;
    font-size: 14px;
    color: rgb(45, 44, 44);
}
.number{
    font-family: sans-serif;
    margin-left: 10px;
    font-size: 14px;
    color: rgb(45, 44, 44);
}
.Ordersdiv {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 15px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}

.Ordersdiv:hover {
    box-shadow: 0 20px 35px rgb(0, 0, 0, 0.1);
}
.ordership{
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 15px;
    margin-top: 15px;
    box-shadow:  rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}
.orderedsaree{
    display: flex;
    width: 50%;
}
.Sareedetail{
    font-family: sans-serif;
    font-size: 1.2rem;
    font-weight: normal;
    color: rgb(45, 44, 44);
}
.Sareedetail:hover{
    color: rgb(101, 21, 100);
    cursor: pointer;
}
.SareeColor{
    font-family: sans-serif;
    font-size: 12px;
    color: rgb(160, 161, 162);
    margin-top: 8px;
}
.Seller{
    font-family: sans-serif;
    font-size: 15px;
    font-weight: normal;
    color: rgb(116, 117, 117);
    margin-top: 10px;
}
.Amount{
    font-family: sans-serif;
    font-weight: bold;
    margin-top: 2rem;
    color: rgb(45, 44, 44);
}
.processdiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
}
.Prodiv{
    display: block;
}
.dotorder{
    display: flex;
    align-items: center;
    margin-top: 15px;
}
.orderdot{
    height: 12px;
    width: 12px;
    background-color: darkgreen;
    border-radius: 50%;
    display: inline-block;
}
.orderSaree{
    margin-left: 20px;
    font-family: sans-serif;
    color: rgb(45, 44, 44);
}
.refunddiv{
    margin-top: 15px;
}
.refund{
    padding: 15px 25px 15px 25px;
    border: 2px solid rgb(101, 21, 100);
    background: #fff;
    color: rgb(101, 21, 100);
    border-radius: 4px;
    font-weight: bold;
}
.refund:hover{
    background: rgb(101, 21, 100);
    color: #fff;
    border: 2px solid rgb(101, 21, 100);
}

@media only screen and (max-width: 1024px) {
    .shippingchild {
        width: 100%;
    }
}

@media only screen and (max-width: 960px) {
    .Ordersdiv {
        display: block;
    }
    .orderedsaree{
        width: 100%;
    }
    .processdiv{
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .address {
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .address {
        font-size: 14px;
    }
    .Sareedetail{
        font-size: 16px;
    }
    .refund{
        width: 120px;
        padding: 10px;
    }
}