.Mainbody3 {
    width: 100%;
    background: #fff;
}

.mysection3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
    height: 80vh;
}

.Boxdiv3 {
    width: 500px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 20px;
}

.LogoName3 {
    color: rgb(101, 21, 100);
    font-size: 2rem;
    font-family: 'Noto Serif Georgian', serif;
    text-align: center;
    padding: 0;
    margin: 0;
}

.contactdesc{
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    font-size: 12px;
    margin-top: 15px;
    line-height: 20px;
    word-spacing: 1px;
}

.edittext1{
    margin-top: 15px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.519);
    border-radius: 4px;
    border: 1px solid #fff;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    color: #000;
    outline: none;
}

.edittext2{
    margin-top: 25px;
    height: 100px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.519);
    border-radius: 4px;
    border: 1px solid #fff;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    color: #000;
    outline: none;
}

.signupbtn{
    margin-top: 25px;
    padding: 10px;
    border-radius: 8px;
    background: rgb(101, 21, 100);
    border: 2px solid rgb(101, 21, 100);
    color: #fff;
    font-weight: bold;
}
.signupbtn:hover{
    background: #fff;
    border: 2px solid rgb(101, 21, 100);
    color: rgb(101, 21, 100);
}


@media only screen and (max-width: 768px){
    .Boxdiv3{
        width: 80%;
    }
}


@media only screen and (max-width: 480px){
    .Boxdiv3{
        width: 90%;
    }
}