.Mainbody2 {
    width: 100%;
    background: url('https://t4.ftcdn.net/jpg/01/65/93/39/360_F_165933977_EkQHMP3cIOaAJXhk6LtgEeQjU9hvOc3S.jpg') no-repeat;
    background-size: cover;
}

.mysection2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.Boxdiv2 {
    width: 520px;
    padding: 1.5rem;
    display: flex;
    height: 500px;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.305);
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1);
    margin: 20px;
}

.centerHalf{
    display: flex;
    justify-content: space-between;
    align-items: center;
 
}



.halfEt{
    width: 230px;
}

.fullEt{
    width: 470px;
}

.LogoName2 {
    color: #fff;
    font-size: 1.5rem;
    font-family: 'Satisfy', cursive;
    text-align: center;
    padding: 0;
    margin: 0;
}

@media (max-width: 1000px) {
    .fullEt {
      width: 400px;
    }

    .Boxdiv2{
        width: 450px;
     
    }
    .halfEt{
        width: 200px;
    }
  }



  @media (max-width: 500px) {
    .fullEt {
      width: 300px;
    }

    .Boxdiv2{
        width: 350px;
     
    }
    .halfEt{
        width: 148px;
    }
  }


.Address{
    width: 100%;
    font-size: 1.2rem;
    text-align: left;
    font-family: 'Nunito', sans-serif;
    color: #000;
    margin-top: 15px;
}

.edittext{
    margin-top: 15px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.519);
    border-radius: 8px;
    border: 1px solid #fff;
    color: #000;
    outline: none;
}

.signupbtn{
    margin-top: 25px;
    padding: 10px;
    border-radius: 8px;
    background: rgb(101, 21, 100);
    border: 2px solid rgb(101, 21, 100);
    color: #fff;
    font-weight: bold;
}
.signupbtn:hover{
    background: #fff;
    border: 2px solid rgb(101, 21, 100);
    color: rgb(101, 21, 100);
}