

.successbody {
    width: 100%;
    background: #fff;
    background-size: cover;

}


.Section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
   
}
.mainSuccessDiv{
    display: flex;
    width:  100%;
    justify-content: end;
    align-items: flex-start;
    margin-right: 200px;
   
}

.bagValues{
    font-size: 14px;
    font-weight: bold;
    color: #575757;
    font-family: 'Nunito', sans-serif;
}

.bagtv{
    font-size: 12px;
    font-weight: 400px;
    font-family: 'Nunito', sans-serif;
    color: #7a7a7a;

}

.lightTv{
    font-weight: 300;
    color: #575757;
    font-family: sans-serif;
  
}

.darkTv{
   

    font-family: sans-serif;
    color: #000;
}

.spacer{
    display: flex;
    width: 100%;
    flex-direction: row;
    background-color: white;
    padding: 0px 12px 0px 12px;
    margin-top: 12px;
    align-items: center;
    justify-content: space-between;
}

.boxshadow{
    box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.SuccessBoxdiv {
    width: 600px;
    padding: 1rem;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.305);
    border-radius: 20px;
    text-align: center;
    box-shadow:  rgb(0 0 0 / 20%) 0px 2px 1px -3px, rgb(0 0 0 / 14%) 0px 1px 2px -2px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    margin: 20px;
  

}

                                    
.bagBox{
    width: 300px;
    margin-left: 100px; 
    display: flex;
     flex-direction: column;
     padding: 12px;

    overflow: scroll;
    background-color: rgba(255, 255, 255, 0.305);
    border-radius: 8px;
    box-shadow:  rgb(0 0 0 / 20%) 0px 2px 1px -3px, rgb(0 0 0 / 14%) 0px 1px 2px -2px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;

}

.successimg{
    width: 100px;
    margin-top: 1rem;
}

.success {
    color: #000;
    font-family: sans-serif;
    text-align: center;
    margin-top: 1rem;
}

.okaybtn{
    width: 135px;


    padding: 15px;
   border-radius: 8px;
       background: #fff;
    color: black;
    border: none;

    font-weight: bold;
}

.styleFont{

    font-family: 'Nunito', sans-serif;
}

.line{
    height: 0.40px;
    margin-top: 8px;
    font-weight: lighter;
    background-color: lightgray;
    width: 95%;
}


@media (max-width: 1150px) {

    .SuccessBoxdiv{
        width: 500px;
    }
    .bagBox{
        margin: 0px;
       
    }
}

@media (max-width: 930px) {
    .mainSuccessDiv{
        display: flex;
        flex-direction: column;
        margin: 0px;
        align-items: center;
        justify-content: center;

    }
    .bagBox{
      margin: 0px;
        width: 500px;
    }
}


@media (max-width: 620px) {


    .SuccessBoxdiv {
        width: 400px;
    }
    .bagBox{
        margin: 0px;
          width: 320px;
      }
}

@media (max-width: 400px) {
    .SuccessBoxdiv {
        width: 300px;
    }
  }
  


