.tophead {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    position: fixed;
    top: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: 1;
}

.Name {
    color: rgb(239, 103, 237);
    font-size: 1.5rem;
    font-family: 'Satisfy', cursive;
    padding: 0;
    margin: 0;
    margin-left: 3rem;
}
.Logindiv{
    margin-right: 3rem;
    display: flex;
}
.Login {
    text-decoration: none;
    color: #000;
    font-size: 15px;
    font-family: 'Nunito', sans-serif;
}

.Regis {
    text-decoration: none;
    font-size: 15px;
    font-family: 'Nunito', sans-serif;
    color: #000;
}

.Login:hover {
    color: rgb(101, 21, 100);
    font-weight: bold;
}

.Regis:hover {
    color: rgb(101, 21, 100);
    font-weight: bold;
}

.facebook{
    color: rgb(101, 21, 100);
    margin-left: 15px;
}

.nav {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(101, 21, 100);
    position: fixed;
    top: 38px;
    z-index: 1;
}

.nav>.nav-header {
    display: inline;
}

.nav>.nav-header>.nav-title {
    display: inline-block;
    font-size: 22px;
    color: #fff;
    padding: 10px 10px 10px 10px;
}

.nav>.nav-btn {
    display: none;
}

.nav>.nav-links {
    display: inline;
    float: right;
    font-size: 18px;
    margin-left: 2.5rem;
}

.nav>.nav-links>.a {
    display: inline-block;
    padding: 13px 10px 13px 10px;
    text-decoration: none;
    font-family: 'Abyssinica SIL', serif;
    letter-spacing: 1px;
    font-weight: lighter;
    font-size: 14px;
    text-align: left;
    color: #efefef;
    cursor: pointer;
}

.nav>.nav-links>.a:hover{
    font-weight: 600;
}

.saree-a{
    text-decoration: none;
    font-family: 'Abyssinica SIL', serif;
    letter-spacing: 1px;
    font-weight: lighter;
    font-size: 14px;
    text-align: left;
    color: #efefef;
}

.saree-a:hover{
    font-weight: 600;
}

.a:hover .hover-div{
    display: initial;
}

.nav>#nav-check {
    display: none;
}
.myicons{
    margin-right: 3rem;
}
.headericon1 {
    color: #fff;
    margin-right: 15px;
}
.headericon1:hover{
    color: #fff;
}
.headericon2 {
    color: #fff;
}
.headericon2:hover{
    color: #fff;
}

.hover-div{
    position: absolute;
    top: 100%;
    width: 200px;
    border: 1px solid #efefef;
    background: #fff;
    padding: 5px 5px 10px 5px;
    display: none;
}

.hover-a{
    color: #000;
    font-weight: lighter;
    margin-top: 10px;
}

.hover-a:hover{
    font-weight: 600;
    color: rgb(101, 21, 100);
}

@media (max-width:960px) {
    .nav>.nav-links>.a {
        font-size: 14px;
    }
    .myicons{
        margin-right: 10px;
    }
    .myicons{
        margin-right: 3rem;
    }
    .headericon{
        font-size: 12px;
    }
}


@media (max-width:720px) {
    .Name{
        font-size: 1.2rem;
    }
    .Login{
        font-size: 12px;
    }
    .Regis{
        font-size: 12px;
    }
    .nav>.nav-btn {
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0px;
    }

    .Name{
        margin-left: 10px;
    }
    .Logindiv{
        margin-right: 10px;
    }
    .facebook{
        font-size: 15px;
        margin-left: 8px;
    }
    .myicons {
        display: inline-block;
        position: absolute;
        right: 0;
        top: 12px;
        margin-right: 10px;
    }

    .nav>.nav-btn>label {
        display: inline-block;
        width: 50px;
        height: 50px;
        padding: 13px;
        padding: 0;
        padding: 10px;
    }

    .nav>.nav-btn>label>span {
        display: block;
        width: 25px;
        height: 8px;
        border-top: 2px solid #eee;
    }

    .nav>.nav-links {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #fff;
        overflow-y: hidden;
        height: 0px;
        transition: all 0.3s ease-in;
        top: 40px;
        left: 0px;
        margin: 0;
    }

    .nav>.nav-links>.a {
        display: block;
        width: 100%;
        color: #000;
    }

    .nav>.nav-links>.a:hover {
        color: #fff;
        background: rgb(101, 21, 100);
    }

    .nav>#nav-check:not(:checked)~.nav-links {
        height: 0px;
    }

    .nav>#nav-check:checked~.nav-links {
        height: calc(100vh - 50px);
        overflow-y: hidden;
        z-index: -2;
    }
}