.body {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(35, 33, 33);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    position: fixed;
    z-index: 1;
}


.svg {
    width: 114px;
    height: 114px;
    margin: 1em;
}

.bg {
    fill: none;
    stroke-width: 5px;
    stroke: #7c7d7e;
}

[class^="meter-"] {
    fill: none;
    stroke-width: 5px;
    stroke-linecap: round;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;

}

.meter-1 {
    stroke-dasharray: 360;
    stroke-dashoffset: 0;
    stroke: rgb(101, 21, 100);
    animation: progress-1 1s infinite;
}

.meter-2 {
    stroke: lime;
    stroke-dasharray: 360;
    stroke-dashoffset: 160;
    animation: progress-2 .75s ease-out;
}

.meter-3 {
    stroke: magenta;
    stroke-dasharray: 360;
    stroke-dashoffset: 250;
    animation: progress-3 .5s ease-out;
}

@keyframes progress-1 {
    from {
        stroke-dashoffset: 360;
    }

    to {
        stroke-dashoffset: 0;
    }
}

@keyframes progress-2 {
    from {
        stroke-dashoffset: 360;
    }

    to {
        stroke-dashoffset: 160;
    }
}

@keyframes progress-3 {
    from {
        stroke-dashoffset: 360;
    }

    to {
        stroke-dashoffset: 250;
    }
}