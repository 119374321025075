.Mainbody {
    width: 100%;
    background: url('https://t4.ftcdn.net/jpg/01/65/93/39/360_F_165933977_EkQHMP3cIOaAJXhk6LtgEeQjU9hvOc3S.jpg') no-repeat;
    background-size: cover;
}

.mysection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.Boxdiv {
    width: 300px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.305);
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1);
    margin: 20px;
}

.LogoName {
    color: #fff;
    font-size: 1.5rem;
    font-family: 'Satisfy', cursive;
    text-align: center;
    padding: 0;
    margin: 0;
}

.signup{
    text-align: left;
    font-family: 'Nunito', sans-serif;
    color: #000;
    margin-top: 15px;
}

.edittext{
    margin-top: 15px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.519);
    border-radius: 8px;
    border: 1px solid #fff;
}

.signupbtn{
    margin-top: 25px;
    padding: 10px;
    border-radius: 8px;
    background: rgb(101, 21, 100);
    border: 2px solid rgb(101, 21, 100);
    color: #fff;
    font-weight: bold;
}
.signupbtn:hover{
    background: #fff;
    border: 2px solid rgb(101, 21, 100);
    color: rgb(101, 21, 100);
}