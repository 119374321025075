
.qrContainer{

    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

.qrImage{
    width: 300px;
    height: 300px;
    object-fit: contain;
    border-radius: 20px;

}


.qrTitle{
    font-family: sans-serif;
    color: rgb(44, 44, 44);
    font-weight: bold;
}

.paytv{
    margin-top: 20px;
    font-family: sans-serif;
}
.subContainer{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
   
}



/* this are steps */

.paymentTV{
    margin: 30px;
}

.qrSharebtn{

  background-color: #02ba61;
  display: none;

    color: white;
    margin-top: 30px;

    outline: none;
    border: none;
    border-radius: 32px;
    width: 150px;
    height: 40px;



}

.stepMain{
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.stepcontainer {
  width: 100%;

}


.qrAmount{

  background-color: #02ba61;
  color: white;
  padding: 8px;
  margin-bottom: 12px;
  margin-top: 12px;
  font-family: sans-serif;
  margin-top: 12px;
  width: 120px;
  display: flex;
  justify-content: center;
  border-radius: 20px;

}

.progressbar {
  counter-reset: step;
}

.progressbar li {
  position: relative;
  list-style: none;
  float: left;
  width: 33.33%;
  text-align: center;
}

/* Circles */
.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 40px;
  height: 40px;

  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: #FF9100;
   
  /* Center # in circle */
  line-height: 39px;
}

.progressbar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: orange ;
  top: 20px; /*half of height Parent (li) */
  left: -50%;
  z-index: -1;
}

.progressbar li:first-child:after {
  content: none;
}

.progressbar li.active:before {
  background: #00E676;
  content: "✔";  
}

.progressbar li.active + li:after {
  background: #00E676;
}


@media (max-width: 650px) {
    .stepMain {
        display:none;
        
    }

    .subContainer{
        width: 100vw;
    }

  
  }
  