.orderparentdiv {
    display: flex;
    justify-content: center;
    margin-top: 6rem;
}

.orderchilddiv {
    width: 80%;
}

.orderboxdiv {
    padding: 10px 10px 50px 10px;
}

.orderheaddiv {
    display: flex;
    padding: 15px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}

.orderhead {
    font-family: 'Nunito', sans-serif;
    color: #000;
}

.ordersdiv {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 15px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}

.ordersdiv:hover {
    box-shadow: 0 20px 35px rgb(0, 0, 0, 0.1);
}

.orderimg {
    width: 100px;
}
.FullOrderdiv{
    display: flex;
    justify-content: space-between;
}
.orderSareename {
    font-family: sans-serif;
    font-size: 15px;
    font-weight: normal;
    color: rgb(45, 44, 44);
    margin-right: 10rem;
    width: 100%;
}

.OrderDetail {
    display: flex;
}

.orderamount {
    font-family: sans-serif;
    font-weight: bold;
    margin-right: 5rem;
    color: rgb(45, 44, 44);
}

.ordersuccessdiv {
    display: block;
}

.dotdiv {
    display: flex;
    align-items: center;
}

.dot {
    height: 12px;
    width: 12px;
    background-color: darkgreen;
    border-radius: 50%;
    display: inline-block;
}

.deliver {
    font-family: sans-serif;
    font-size: 15px;
    margin-left: 20px;
    color: rgb(45, 44, 44);
}

.deliverdisc {
    margin-top: 10px;
    font-family: sans-serif;
    font-size: 12px;
    color: rgb(45, 44, 44);
}

@media only screen and (max-width: 1200px) {
    .orderchilddiv {
        width: 90%;
    }
}

@media only screen and (max-width: 1024px) {
    .orderchilddiv {
        width: 100%;
    }
}
@media only screen and (max-width: 960px) {
    .FullOrderdiv {
        display: block;
        margin-left: 1rem;
    }
    .OrderDetail{
        display: block;
        margin-top: 1rem;
    }
    .ordersdiv{
        display: flex;
        justify-content: left;
    }
    .dotdiv{
        margin-top: 15px;
    }
    .deliver{
        margin-left: 8px;
    }
}

@media only screen and (max-width: 1024px) {
    .orderhead{
        font-size: 25px;
    }
}