.parentdiv {
    display: flex;
    justify-content: center;
    margin-top: 6rem;
}

.detailfulldiv {
    width: 80%;
    display: flex;
}

.imagesdiv {
    width: 50%;
}



.imagesdiv {
    display: flex;
}

.column {
    width: 20%;
    display: block;
    height: 600px;
    overflow: scroll;
}

::-webkit-scrollbar{
    width: 0;
    background: transparent;
}

.columnimg {
    width: 100%;
}

.fullimg {
    width: 80%;
    object-fit: cover;
    margin-left: 10px;
}

.myfullimg {
    width: 100%;
}

.imgdetaildiv {
    width: 50%;
    margin-left: 20px;
    display: block;
}

.Sareename {
    font-family:  sans-serif;
    font-weight: normal;
    font-size: 1.8rem;
    color: rgb(45, 44, 44);
}

.mrp {
    font-family: sans-serif;
    margin-top: 20px;
    color: rgb(45, 44, 44);
    font-size: 17px;
}

.tax {
    font-family: sans-serif;
    color: rgb(50, 50, 50);
    letter-spacing: 0.5px;
    font-size: 14px;
    margin-top: 10px;
}

.addquantity {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.addicon {
    color: rgb(138, 134, 134);
}

.quantity {
    margin: 10px;
    font-family: sans-serif;
}

.btns {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.addbtn {
    padding: 15px 35px 15px 35px;
    border-radius: 8px;
    border: 1px solid #E23384;
    background: #E23384;
    color: #fff;
}

.addbtn:hover {
    border: 1px solid rgb(101, 21, 100);
    background: rgb(101, 21, 100);
}

.buybtn {
    padding: 15px 45px 15px 45px;
    border-radius: 8px;
    border: 1px solid #000;
    background: #000;
    color: #fff;
    margin-left: 1rem;
}

.sareedetail {
    font-family: sans-serif;
    font-size: 12px;
    margin-top: 1rem;
}

.sharediv {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.share {
    font-family: sans-serif;
}

.shareicons {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.myShare {
    color: rgb(101, 21, 100);
    margin-left: 15px;
}




@media only screen and (max-width: 1300px) {
    .column {
        height: 550px;
    }
}

@media only screen and (max-width: 1224px) {
    .column {
        height: 500px;
    }
}

@media only screen and (max-width: 1124px) {
    .column {
        height: 450px;
    }
}

@media only screen and (max-width: 1024px) {
    .column {
        height: 400px;
    }
}

@media only screen and (max-width: 924px) {
    .column {
        height: 350px;
    }
}


@media only screen and (max-width: 824px) {
    .column {
        height: 320px;
    }
}


@media only screen and (max-width: 780px) {
    .detailfulldiv {
        display: block;
    }

    .imagesdiv {
        width: 100%;
    }

    .imgdetaildiv {
        width: 100%;
        margin-top: 1rem;
        margin-left: 0;
    }

    .addbtn {
        padding: 15px 20px 15px 20px;
    }

    .buybtn {
        padding: 15px 20px 15px 20px;
    }

    .column {
        height: 600px;
    }
}

@media only screen and (max-width: 680px) {
    .column {
        height: 500px;
    }
}

@media only screen and (max-width: 580px) {
    .column {
        height: 420px;
    }
}

@media only screen and (max-width: 480px) {
    .column {
        height: 350px;
    }
}

@media only screen and (max-width: 380px) {
    .column {
        height: 300px;
    }
}