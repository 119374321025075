.cartparent {
    display: flex;
    justify-content: center;
    margin-top: 6rem;
}

.childpayment {
    width: 80%;
    display: flex;
    padding: 10px 10px 50px 10px;
}

.Paymentdiv {
    width: 70%;
}

.paymenthead {
    display: flex;
    padding: 15px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}

.payheading {
    font-family: sans-serif;
    color: #000;
}

.Deliveryaddress {
    background: #fff;
    padding: 15px;
    margin-top: 15px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}

.DelAddress {
    font-family: 'Montserrat', sans-serif;
}

.CustomerName {
    font-family: sans-serif;
    margin-top: 10px;
    font-weight: bold;
}

.MyAdd {
    font-family: 'Montserrat', sans-serif;
    margin-top: 10px;
    width: 40%;
    line-height: 25px;
}

.PhnDetails {
    display: flex;
    margin-top: 10px;
}

.Phonenbr {
    font-family: sans-serif;
    font-weight: bold;
    font-size: 14px;
}

.MyNumber {
    font-family: sans-serif;
    margin-left: 10px;
    font-size: 14px;
}

.paymentoptions {
    display: block;
    background: #fff;
    margin-top: 15px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}

.paymentoptions:hover {
    box-shadow: 0 20px 35px rgb(0, 0, 0, 0.1);
}

.payoption {
    background: rgb(101, 21, 100);
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    padding: 15px;
}

.mypay {
    display: block;
    padding: 15px;
}

.payment1 {
    display: flex;
    align-items: center;
    margin-top: 15px;
    cursor: pointer;
}

/* .payselect{
    width: 15px;
    height: 15px;
    border-radius: 20px;
    border: 2px solid rgb(85, 84, 84);
    background: #fff;
} */
.payname1 {
    font-family: sans-serif;
    margin-left: 10px;
}

.payment1 #pay-check:checked {
    background: green;
}


.continuediv {
    display: flex;
    justify-content: end;
    margin-top: 15px;
    padding: 15px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}

.continuediv:hover {
    box-shadow: 0 20px 35px rgb(0, 0, 0, 0.1);
}

.continue {
    padding: 15px 35px 15px 35px;
    background: #FB641B;
    color: #fff;
    font-weight: bold;
    border-radius: 4px;
    border: 2px solid #FB641B;
}

.continue:hover {
    border: 2px solid #FB641B;
    background: #fff;
    color: #FB641B;
}

.PriceDiv {
    width: 30%;
    height: 350px;
    margin: 20px;
    display: block;
    background: #fff;
    padding: 20px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}

.PriceDiv:hover {
    box-shadow: 0 20px 35px rgb(0, 0, 0, 0.1);
}


.Price {
    font-family: sans-serif;
    font-size: 1rem;
    color: rgb(116, 117, 117);
    padding-bottom: 15px;
    border-bottom: 1px solid rgb(213, 215, 215);
}

.Myprice {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}

.Pname {
    font-family: sans-serif;
    font-size: 16px;
}

.MyAmt {
    font-family: sans-serif;
    font-size: 1rem;
    font-weight: bold;
}

.MyAmt2 {
    font-family: sans-serif;
    font-size: 1rem;
    color: darkgreen;
}

.Totalamountdiv {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid rgb(213, 215, 215);
    border-bottom: 1px solid rgb(213, 215, 215);
}

.Total {
    font-family: sans-serif;
    font-size: 1.2rem;
}

.Totalamt {
    font-family: sans-serif;
    font-size: 1.2rem;
}

.Save {
    margin-top: 2rem;
    font-family: sans-serif;
    font-size: 1rem;
    color: darkgreen;
}

.continuediv2 {
    display: flex;
    justify-content: end;
    margin-top: 1rem;
    display: none;
}



@media only screen and (max-width: 1200px) {
    .childpayment {
        width: 90%;
    }
}

@media only screen and (max-width: 960px) {
    .childpayment {
        display: block;
        align-items: center;
    }

    .Paymentdiv {
        width: 100%;
    }

    .PriceDiv {
        width: 100%;
        margin-left: 0;
        height: 400px;
    }

    .continuediv {
        display: none;
    }

    .Save {
        margin-bottom: 1rem;
    }

    .continuediv2 {
        display: initial;
    }
}

@media only screen and (max-width: 480px) {


    .payheading {
        font-size: 25px;
    }
}


@media only screen and (max-width: 768px) {
    .MyAdd {
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .MyAdd {
        font-size: 14px;
    }
}