.parentdiv{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.fullcatdiv{
    width: 80%;
    padding: 10px 10px 50px 10px;
}
.heading{
    text-align: left;
}
.catdiv{
    background: #fff;
    margin-top: 1rem;
    padding: 20px;
    border-radius: 4px;
    box-shadow:  rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}
.catead{
    text-align: left;
    font-family: 'Lora', serif;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(229, 227, 227);
}
.grid-container2 {
    display: grid;
    grid-template-columns: auto auto auto;
    margin: 0;
  }
  .grid-item2 {
    font-size: 15px;
    text-align: left;
    margin: 0;
    margin-top: 10px;
    font-family: 'Lora', serif;
  }