.cartparent{
    display: flex;
    justify-content: center;
    margin-top: 6rem;
}
.childcart{
    width: 80%;
    display: flex;
    padding: 10px 10px 50px 10px;
}
.cartdiv{
    width: 70%;
}
.headingdiv{
    display: flex;
    padding: 15px;
    box-shadow:  rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}
.heading{
    font-family: sans-serif;
    color: #000;
}

.Deliveryaddress {
    background: #fff;
    padding: 15px;
    margin-top: 15px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}

.DelAddress {
    font-family: 'Montserrat', sans-serif;
    color: rgb(45, 44, 44);
}

.CustomerName {
    font-family: sans-serif;
    margin-top: 10px;
    font-weight: bold;
    color: rgb(45, 44, 44);
}

.MyAdd {
    font-family: 'Montserrat', sans-serif;
    margin-top: 10px;
    width: 40%;
    line-height: 25px;
    color: rgb(45, 44, 44);
}

.PhnDetails {
    display: flex;
    margin-top: 10px;
    align-items: center;
}

.Phonenbr {
    font-family: sans-serif;
    font-weight: bold;
    font-size: 14px;
    color: rgb(45, 44, 44);
}

.MyNumber {
    font-family: sans-serif;
    margin-left: 10px;
    font-size: 14px;
    color: rgb(45, 44, 44);
}

.addChange-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
}

.change-btn{
    padding: 5px 10px 5px 10px;
    background: rgb(101, 21, 100);
    color: #fff;
    font-weight: bold;
    border-radius: 4px;
    text-align: center;
    border: 2px solid rgb(101, 21, 100);
}

.change-btn:hover{
    border: 2px solid rgb(101, 21, 100);
    background: #fff;
    color: rgb(101, 21, 100);
}


.savedcart{
    display: flex;
    background: #fff;
    padding: 15px;
    margin-top: 15px;
    box-shadow:  rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}
.savedcart:hover{
    box-shadow: 0 20px 35px rgb(0, 0, 0, 0.1);
}
.cartimg{
    width: 120px;
}
.details{
    width: 100%;
    display: block;
    margin-left: 10px;
}
.deleterow{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.delete{
    color: rgb(241, 75, 75);
    font-size: 20px;
}
.SareeDetail{
    font-family: sans-serif;
    color: rgb(45, 44, 44);
    letter-spacing: 1px;
    font-size: 1.2rem;
    font-weight: normal;
}
.SareeDetail:hover{
    color: rgb(101, 21, 100);
    cursor: pointer;
}
.sareecolor{
    font-family: sans-serif;
    font-size: 12px;
    color: rgb(160, 161, 162);
    margin-top: 8px;
}
.seller{
    font-family: sans-serif;
    font-size: 15px;
    font-weight: normal;
    color: rgb(116, 117, 117);
    margin-top: 10px;
}
.MyNumber{
    font-family: sans-serif;
    color: rgb(45, 44, 44);
}
.amount{
    font-family: sans-serif;
    font-weight: bold;
    margin-top: 2rem;
}
.orderdiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    padding: 15px;
    box-shadow:  rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}
.orderdiv:hover{
    box-shadow: 0 20px 35px rgb(0, 0, 0, 0.1);
}

.orderdiv3{
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 15px;
    padding: 15px;
    box-shadow:  rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}
.orderdiv3:hover{
    box-shadow: 0 20px 35px rgb(0, 0, 0, 0.1);
}

.mypay {
    display: block;
}

.payment1 {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.payment2 {
    display: flex;
    align-items: center;
    margin-top: 15px;
    cursor: pointer;
}

.order{
    height: 60px;
    padding: 15px 35px 15px 35px;
    background: rgb(101, 21, 100);
    color: #fff;
    font-weight: bold;
    border-radius: 4px;
    border: 2px solid rgb(101, 21, 100);
}
.order:hover{
    border: 2px solid rgb(101, 21, 100);
    background: #fff;
    color: rgb(101, 21, 100);
}

.order2{
    height: 60px;
    padding: 15px 35px 15px 35px;
    background: rgb(101, 21, 100);
    color: #fff;
    font-weight: bold;
    border-radius: 4px;
    margin-top: 1rem;
    border: 2px solid rgb(101, 21, 100);
}
.order2:hover{
    border: 2px solid rgb(101, 21, 100);
    background: #fff;
    color: rgb(101, 21, 100);
}

.pricediv{
    width: 30%;
    height: 350px;
    margin: 20px;
    display: block;
    background: #fff;
    padding: 20px;
    box-shadow:  rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}
.pricediv:hover{
    box-shadow: 0 20px 35px rgb(0, 0, 0, 0.1);
}


.price{
    font-family: sans-serif;
    font-size: 1rem;
    color: rgb(116, 117, 117);
    padding-bottom: 15px;
    border-bottom: 1px solid rgb(213, 215, 215);
}

.myprice{
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}
.pname{
    font-family: sans-serif;
    font-size: 16px;
}
.amt{
    font-family: sans-serif;
    font-size: 1rem;
    font-weight: bold;
}
.amt2{
    font-family: sans-serif;
    font-size: 1rem;
    color: darkgreen;
}
.Tamountdiv{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid rgb(213, 215, 215);
    border-bottom: 1px solid rgb(213, 215, 215);
}
.total{
    font-family: sans-serif;
    font-size: 1.2rem;
}
.tamt{
    font-family: sans-serif;
    font-size: 1.2rem;
}
.save{
    margin-top: 2rem;
    font-family: sans-serif;
    font-size: 1rem;
    color: darkgreen;
}

.orderdi2{
    display: flex;
    justify-content: end;
    margin-top: 1rem;
    display: none;
}

.orderdiv4{
    display: flex;
    justify-content: end;
    margin-top: 1rem;
    display: none;
}



@media only screen and (max-width: 1200px){
    .childcart{
        width: 90%;
    }
}
@media only screen and (max-width: 960px){
    .childcart{
        display: block;
        align-items: center;
    }
    .cartdiv{
        width: 100%;
    }
    .pricediv{
        width: 100%;
        margin-left: 0;
        height: 450px;
    }
    .orderdiv{
        display: none;
    }
    
    .save{
        margin-bottom: 1rem;
    }
    .orderdi2{
        display: initial;
    }
    .orderdiv4{
        display: initial;
    }
    .order{
        height: 50px;
    }

    .order2{
        height: 50px;
    }

    .addChange-div{
        width: 70%;
    }
}

@media only screen and (max-width: 480px){
 
   
    .heading{
        font-size: 25px;
    }
    .SareeDetail{
        font-size: 14px;
    }
}