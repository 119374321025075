* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

}

body {
  margin: 0px;
  font-family: 'segoe ui';
  
}

.Topdiv{
  margin-top: 7rem;
  margin-left: 3rem;
}
.Totalsaree {
  font-size: 14px;
  font-weight: bold;
  font-family: sans-serif;
  color: rgb(118, 115, 115);
  margin: 0;
}

.btncontainer {
  display: none;
  position: fixed;
  width: 100%;
  margin-top: 50px;
  bottom: 0;
  z-index: 1;
  justify-content: space-evenly;

}

.sortbox2{
  width: 50%;
  display: flex;
  flex-direction: column-reverse;
}
.sortbydiv{
  width: 100%;
  position: absolute;
  bottom: 48px;
  background: #fff;
  padding: 20px;
}
.sortdiv{
  display: flex;
  align-items: center;
  margin: 15px;
}
.sortname{
  margin-left: 10px;
  font-family: sans-serif;
  font-weight: bold;
}
.Cancelbtn{
  width: 50%;
  padding: 15px;
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: rgb(101, 21, 100);
  border: 2px solid rgb(101, 21, 100);
  background: #fff;
  border-radius: 8px;
}
.Applybtn{
  width: 50%;
  padding: 15px;
  margin-left: 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  border: 2px solid rgb(101, 21, 100);
  background: rgb(101, 21, 100);
  border-radius: 8px;
}




.sortbox {
  width: 100%;
  display: flex;
  height: 10px;
  margin: 0;
  padding: 0;
  flex-direction: column-reverse;
}
.by:hover{
  background: #fff;
  color: rgb(101, 21, 100);
}
.by{
  font-family: sans-serif;
  padding: 15px;
  border-width: 2px;
  text-align: center;
  background: rgb(101, 21, 100);
  color: #fff;
  border-right: 1px solid #fff;
}




.bodydiv{
  display: flex;
  padding-left: 3rem;
  padding-right: 3rem;
  align-items: flex-start;
}





.Sortbydiv{
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 48px;
  background: #fff;
  padding: 20px;
}
.Sortdiv{
  display: flex;
  align-items: center;
  margin: 15px;
}
.Sortname{
  margin-left: 10px;
  font-family: sans-serif;
  font-weight: bold;
}
.applyfilter{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cancelbtn{
  width: 50%;
  padding: 15px;
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: rgb(101, 21, 100);
  border: 2px solid rgb(101, 21, 100);
  background: #fff;
  border-radius: 8px;
}
.applybtn{
  width: 50%;
  padding: 15px;
  margin-left: 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  border: 2px solid rgb(101, 21, 100);
  background: rgb(101, 21, 100);
  border-radius: 8px;
}


.applyBtn{
  background-color: purple;
  color: white;
  padding: 12px;
  outline: none;
  border-width: 0px;
  border-radius: 20px;
}

.mybox {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  height: fit-content;
  width: 250px;
  background: #F5F5F5;
  padding: 40px 20px ;
  margin-right: 10px;
}

.sheetdiv{
  display: flex;
  flex-direction: column;
}


.myslider{
  width: 100%;
  max-height: 2px;
  margin: 12px 0px 20px 0px;
  accent-color: purple;

}

.mysheet{
width: 100vw;
border-top-right-radius: 20px;
border-top-left-radius: 20px;
z-index: 1;
height: 250px;
background-color: white;
overflow: scroll;
  /* width: '100vw',
  borderTopRightRadius: '20px', borderTopLeftRadius: '20px',
  zIndex: '1',
  height: '280px', backgroundColor: 'white', overflow: 'scroll' */

}

.filterdiv{
  display: flex;
  justify-content: space-between;
  width: 220px;
  padding-bottom: 20px;
}
.filter{
  font-family:  sans-serif;
  color: #000;
  font-weight: lighter;
  font-size: 12px;
  letter-spacing: 1px;
}
.smalltxt{
  font-family:  sans-serif;
  color: #575757;
  font-weight: lighter;
  font-size: 12px;
  letter-spacing: 1px;

}
.titles{
  font-family:  sans-serif;
  color: #1a1a1a;
  font-weight: lighter;
  font-size: 16px;
  letter-spacing: 1px;
}

.clear{
  font-family: sans-serif;
  font-size: 11px;
  font-weight: normal;
  letter-spacing: 2px;
  color: rgb(137, 135, 135);
  border-bottom: 1px solid red;
  padding-bottom: 5px;
}
.myFilters{
  display: block;
}
.allfilters{
  display: block;
}


.SortDiv{
  display: flex;
  align-items: center;
  border-top: 1px solid #e7e6e6;
  padding-top: 20px;
  justify-content: space-between;
  padding-bottom: 20px;
}
.SortName{
  margin-left: 10px;
  font-family: sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 13px;
  color: rgb(45, 44, 44);
}

.mytitle{
  font-family: sans-serif;
  font-weight: 400;
  margin-top: 18px;
  letter-spacing: 1px;
  font-size: 15px;
  cursor: pointer;
}


@media (max-width: 1000px) {
  .btncontainer {
    display: flex;
    

 
  }
}

@media (max-width: 400px) {
  .mytitle {
    font-size: 12px;
    white-space: nowrap;
  }
}




.sareeimg {
  width: 300px;
  height: 380px;
  margin: 14px;
  padding: 12px;
  object-fit: cover;
}




.mygrid {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr)
}


@media (max-width: 600px) {
  .sortcontainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: red;
    overflow-y: hidden;
    height: 0px;
    transition: all 0.3s ease-in;
    top: 50px;
    left: 0px;
  }
}

@media only screen and (max-width: 1000px) {
  .mybox {
    display: none;
    height: 0px;
    width: 0px;
  }
}




@media only screen and (max-width: 780px) {
  .mygrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr)
  }
  .Topdiv{
    margin-left: 10px;
  }
  .sareeimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 20px;
  }
  .bodydiv{
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
  }
  .grid{
    margin-top: 15px;
    margin: 0;
  }
  .mytitle{
    margin-top: 5px;
  }
}



/* this is slider */
