
.my-filter{
    width: 250px;
    border: 1px solid rgb(214, 213, 213);
    border-radius: 4px;
}.filter-p{
    font-family: sans-serif;
    font-size: 15px;
    font-weight: bold;
    margin-top: 15px;
    padding-bottom: 15px;
    margin-left: 5px;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-top: 1px solid rgb(214, 213, 213);
    padding: 0;
  }
  .grid-item {
    border: 1px solid rgb(214, 213, 213);
    border-radius: 4px;
    padding: 5px;
    margin: 5px;
  }
  .item {
    padding: 20px;
    border-radius: 4px;
  }