footer{
  display: flex;
  flex-direction: column;
}
.container{
  display: flex;
  padding: 3rem;
  background-color: #651564;
}
.leftdiv {
  width: 30%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.logoname {
  color: #fff;
  font-size: 1.5rem;
  font-family: 'Satisfy', cursive;
  text-align: left;
  padding: 0;
  margin: 0;
}

.webdesc {
  color: rgba(255, 255, 255, 0.498);
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-family: sans-serif;
  text-align: left;
  word-spacing: 1px;
  line-height: 20px;
  margin-top: 15px;
}

.subdiv {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.contactdiv {
  text-align: left;
  width: 20%;
  margin-left: 2rem;
  margin-top: 1rem;
}

.cinfo {
  color: #fff;
  font-family: sans-serif;
  font-size: 0.8rem;
  padding-bottom: 10px;
  margin: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.133);
}

.allinfo {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.infoh {
  color: rgba(255, 255, 255, 0.498);
  margin: 0;
  margin-top: 15px;
  font-size: 0.8rem;
  font-family: sans-serif;
}

.infoa {
  color: rgba(255, 255, 255, 0.498);
  margin: 0;
  margin-top: 15px;
  font-size: 0.8rem;
  text-decoration: none;
}

.info {
  color: rgba(255, 255, 255, 0.813);
  margin: 0;
  margin-top: 5px;
  font-size: 0.8rem;
  font-family: sans-serif;
}


.bottomfooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #000;
}

.copyright {
  color: #fff;
  font-size: 0.8rem;
  font-family: sans-serif;
}

.socialicons {
  display: flex;
  flex-direction: row;
  margin-left: 4rem;
}

.iconimg {
  width: 30px;
  margin: 10px;
}


@media only screen and (max-width: 600px){
  .container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .leftdiv{
    width: 100%;
  }
  .contactdiv{
    width: 100%;
    margin-left: 0;
  }
  .bottomfooter{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
  }
  .copyright{
    margin-top: 15px;
  }
  .socialicons{
    margin-left: 0;
  }
}

@media only screen and (max-width: 1000px){
  .bottomfooter{
    margin-bottom: 48px;
  }
}



figure {
    max-width: 100%;
  }
  html,
  body {
    overflow-x: hidden;
    color: #000;

    -webkit-font-smoothing: antialiased;
  }
  a,
  a:hover,
  a:focus,
  a:active {
    text-decoration: none;
    color: inherit;
  }
  a {
    -webkit-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
  }
  .ct-u-paddingTop10 {
    padding-top: 10px !important;
  }
  .ct-footer {
    padding-top: 8px;
    margin-top: 20px;
    position: relative;
  }
  .ct-footer-pre {
    width: 100%;
    padding-bottom: 55px;
    border-bottom: 1px solid #555;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .ct-footer-pre span {
    font-family: 'Open Sans Condensed', sans-serif;
    color: #ebebeb;
    font-size: 30px;
  }
  .ct-footer-pre .form-group {
    position: relative;
    margin: 0;
  }
  .ct-footer-pre .form-group:before,
  .ct-footer-pre .form-group:after {
    content: '';
    display: table;
  }
  .ct-footer-pre .form-group:after {
    clear: both;
  }
  .ct-footer-pre .form-group input {
    border: 1px solid #fff;
    background-color: #333;
    color: #fff;
    height: 50px;
    padding: 0 30px;
    margin: 0 5px;
    border-radius: 0 !important;
  }
  .ct-footer-pre .form-group button {
    height: 50px;
    position: relative;
    width: 80px;
    padding: 0
  }
  .ct-footer-list {
    padding: 50px 0;
    list-style: none;
    padding-left: 0;
    display: table;
    width: 100%;
    border-bottom: 1px solid #555;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .ct-footer-list > li .ct-footer-list-header {
    font-family: 'Open Sans Condensed', sans-serif;
    color: #fff;
    font-size: 30px;
  }
  .ct-footer-list > li ul {
    list-style: none;
    padding-left: 0;
  }
  .ct-footer-list > li ul li a {
    color: #fff;
  }
  .ct-footer-list > li ul li a:hover,
  .ct-footer-post a:hover {
    text-decoration: underline;
  }
  .ct-footer-post {
    background: #000;
    padding: 30px 0;
  }
  .ct-footer-post .inner-left,
  .ct-footer-post .inner-right {
    padding: 20px 0;
  }
  .ct-footer-post ul {
    list-style: none;
    padding-left: 0;
    margin: 0 -20px;
  }
  .ct-footer-post ul li {
    display: inline-block;
    margin: 0 20px;
  }
  .ct-footer-post a {
    color: #000;
  }
  .ct-footer-post p {
    color: #000;
  }
  .ct-footer-meta {
    padding-top: 30px;
  }
  .ct-footer-meta .ct-socials {
    padding: 20px 0;
  }
  .ct-footer-meta .ct-socials li {
    padding: 0 3px;
  }
  .ct-footer--with-button {
    padding-top: 150px;
  }
  address {
    color: #000;
    display: inline-block;
  }
  address span {
    font-weight: 600;
  }
  address a {
    color: #000;
  }
  address a:hover {
    text-decoration: underline;
  }
  .btn {
    font-family: 'Open Sans Condensed', sans-serif;
    border-radius: 0;
    border: none;
    text-transform: uppercase;
    color: #fff;
    font-size: 26px;
    padding: 12px 30px;
  }
  .btn.btn-motive {
    background-color: #00bff3;
    -webkit-transition: all .25s ease;
    transition: all .25s ease;
  }
  .btn.btn-motive:hover,
  .btn.btn-motive:hover:active {
    background-color: #00bff3;
  }
  .btn.btn-violet {
    color: #fff;
    background-color: #4f4f99;
    -webkit-transition: all .25s ease;
    transition: all .25s ease;
  }
  
  
  .btn.btn-white:hover:active {
    background-color: #c9c9c9
  }
  .btn.btn-large {
    padding: 20px 50px;
    font-size: 30px;
    white-space: normal;
  }
  .ct-mediaSection {
    background-attachment: fixed;
  }
  .ct-section_header--type1 {
    font-family: 'Open Sans Condensed', sans-serif;
    color: #000;
    font-size: 115px;
    text-transform: uppercase;
  }
  .ct-section_header--type2 small {
    font-family: 'coquette', fantasy;
    font-size: 58px;
    line-height: .7;
    display: block;
    font-weight: 700;
    position: relative;
    left: -12px;
  }
  .ct-section_header--type2 span {
    font-family: 'Bebas Neue';
    font-size: 115px;
    line-height: .8;
  }
  .ct-section_header--type2 img {
    display: inline-block;
  
    position: relative;
    top: 15px;
    padding-right: 3px;
  }
  .ct-section_header--type3 {
    text-align: center;
  }
  .ct-section_header--type3 span {
    font-family: 'Bebas Neue';
    font-size: 150px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: .85
  }
  .ct-section_header--type4 {
    text-align: center;
  }
  .ct-section_header--type4:before,
  .ct-section_header--type4:after {
    content: '';
    display: table
  }
  .ct-section_header--type4:after {
    clear: both
  }
  .ct-section_header--type4 small {
    font-family: 'coquette', fantasy;
    font-size: 50px;
    color: inherit;
    font-weight: 700;
    display: block
  }
  .ct-section_header--type4 span {
    font-family: 'nimbus-sans-condensed', sans-serif;
    font-weight: 400;
    font-weight: bold;
    font-size: 150px;
    text-transform: uppercase;
    display: block;
    line-height: .7
  }
  
  .ct-section_header--type4 + p {
    font-family: 'nimbus-sans-condensed', sans-serif;
    font-weight: 400;
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
  }
  
  /* Media Queries */
  
  @media (min-width:1200px) {
    .ct-footer-pre {
      display: table;
    }
    .ct-footer-pre > .inner {
      display: table-cell;
      vertical-align: middle;
    }
    .ct-footer-list > li {
      width: 20%;
      display: table-cell;
      vertical-align: top;
    }
    .ct-footer-list > li:last-child {
      width: 7%;
    }
  }
  
  @media (max-width:1199px) {
    .ct-footer-pre .form-group {
        padding-top: 15px
    }
  }
  @media (max-width: 1199px) {
    .ct-footer-list > li {
      display: inline-block;
      float: left;
    }
  }
  @media (min-width:992px) {
    .ct-footer-post .inner-left {
      float: left;
    }
    .ct-footer-post .inner-right {
      float: right;
    }
  }
  @media (max-width:991px) {
    .ct-footer-post {
      text-align: center;
    }
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    .ct-footer-list > li {
      width: 33.3333%;
    }
  }
  @media (min-width:768px) {
    .ct-footer-post p {
      display: inline-block;
    }
    .ct-footer-post p + p {
      padding-left: 50px;
    }
  }
  @media (max-width:767px) {
    address {
      padding-top: 30px;
    }
  }
  @media (min-width: 480px) and (max-width:767px) {
    .ct-footer-list > li {
      width: 50%;
    }
  }
  
  @media (max-width:479px) {
   
    .ct-footer-list > li {
      width: 100%;
      text-align: center;
    }
    .ct-footer-list {
      padding: 20px 0;
    }
    .btn.btn-large {
      padding: 20px 10px;
      line-height: .9;
      font-size: 26px;
      letter-spacing: -.2px;
    }
    .ct-section_header--type1 {
      font-size: 60px;
      line-height: .8;
    }
  
  }